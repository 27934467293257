import React from "react";
import { Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faSpotify, faItunesNote, faBandcamp, faTiktok } from "@fortawesome/free-brands-svg-icons";


export default function OneSheetIntro() {
    return (
        <section id="onesheet-intro" className="onesheet-intro">
{/* 
            <div className="intro-overlay">
            </div> */}
            <div className="intro-content">
                {/* <ReactPlayer
                url={"https://www.youtube.com/watch?v=Yoyqt21PMQQ"}
                playing={true}
                controls={false}
                loop={true}
                muted={true}
                width={"100vw"}
                height={"102vh"}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    pointerEvents: 'none'
                }}
            /> */}
                <Row>
                    <Col className="col-twelve" style={{alignContent:"center"}}>

                        <h1 style={{ fontFamily: "Minion Pro", fontSize: "84px", fontWeight: "600", letterSpacing: "0.1em", marginTop:"6rem" }}>AVA IN THE DARK</h1>
                        <h2>NEW TRACK 'SWIM' OUT NOVEMBER 2024 </h2>
                        <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                            <ReactPlayer
                                url={"https://youtu.be/H2CZFDXlZRw"}
                                playing={false}
                                controls={true}
                                loop={true}
                                muted={false}
                                width={"100%"}
                                height={"100%"}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    zIndex: 2
                                }}
                            />
                        </div>
                        <p className="intro-position"></p>
                        <ul className="onesheet-intro-social">
                            <li>
                                <a href="https://www.instagram.com/avainthedark/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UCo-bPwCSh3jHZYl_W3i0t3A" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faYoutube} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://open.spotify.com/artist/5UFq82PvmEauTLHRqGJIFv?si=AEvAfv0zQFGyIFu5T8aJUw" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faSpotify} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://music.apple.com/gb/artist/ava-in-the-dark/1450407649" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faItunesNote} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://avainthedark.bandcamp.com/?ltclid=e4ba4206-b697-42f2-8fb3-8b8733466afd" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faBandcamp} size="xl" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.tiktok.com/@avainthedark" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faTiktok} size="xl" />
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>


            </div>
        </section>
    );
}
