import { faInstagram, faItunesNote, faSpotify, faTiktok, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getSpotifyToken } from "../../services/spotifyService";
import { SpotifyArtist } from "../../services/interfaces";

function SocialStats() {

    const [token, setToken] = useState<string | null>(null);
    const [socialStats, setSocialStats] = useState<SpotifyArtist | null>(null);

    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getSpotifyToken();
            setToken(fetchedToken);
        };

        fetchToken();
    }, []);

    // Fetch social stats from Spotify
    useEffect(() => {
        const fetchSocialStats = async () => {
            if (!token) {
                return;
            }

            console.log("Token:", token);

            try {
                const response = await fetch('https://api.spotify.com/v1/artists/5UFq82PvmEauTLHRqGJIFv', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch Spotify data');
                }
                const data: SpotifyArtist = await response.json();
                setSocialStats(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchSocialStats();
    }, [token]);


    return (

        <section className="live-alt" id="live-alt">

            <Row className="section-intro">
                <Col className="col-twelve">
                    <h5>SOCIALS</h5>
                </Col>
            </Row>
            <Row className="portfolio-content" style={{ textAlign: 'center' }}>
                <Col className="col-twelve">
                    <ul className="onesheet-social">
                        <li style={{ display: 'block', margin: '20px 0' }}>
                            <a href="https://www.instagram.com/avainthedark/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="xl" />
                                <h3>Instagram</h3>
                                <h3>Followers: 1,657</h3>
                            </a>
                        </li>
                        <li style={{ display: 'block', margin: '60px 0' }}>
                            <a href="https://open.spotify.com/artist/5UFq82PvmEauTLHRqGJIFv?si=AEvAfv0zQFGyIFu5T8aJUw" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faSpotify} size="xl" />
                                <h3>Spotify</h3>
                                {socialStats ? (
                                    <h3>Followers: {socialStats.followers.total.toString()}</h3>
                                ) : (<h3>Followers: 678 </h3>)}
                            </a>
                        </li>
                        <li style={{ display: 'block', margin: '60px 0' }}>
                            <a href="https://www.tiktok.com/@avainthedark" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTiktok} size="xl" />
                                <h3>TikTok</h3>
                                <h3>Followers: 597</h3>
                            </a>
                        </li>
                        <li style={{ display: 'block', margin: '60px 0' }}>
                            <a href="https://www.youtube.com/channel/UCo-bPwCSh3jHZYl_W3i0t3A" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faYoutube} size="xl" />
                                <h3>YouTube</h3>
                                <h3>Subscribers: 97</h3>
                            </a>
                        </li>
                        <li style={{ display: 'block', margin: '60px 0' }}>
                            <FontAwesomeIcon icon={faEnvelope} size="xl" color="white" />
                            <h3>Mailing List</h3>
                            <h3>Subscribers: 58</h3>
                        </li>

                    </ul>
                </Col>
                <col className="col-twelve" />
            </Row>
        </section>
    );
}

export default SocialStats;